import { useEffect, useRef } from 'react';
import MultiLingualContent from '../hooks/MultiLingualContent.js';

//animation
import { motion } from 'framer-motion';

// styles & icons
import './Home.css';
import Button from 'react-bootstrap/Button';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import caldoaerial from '../images/caldoaerial.jpg';
import yummy from '../images/yummy.jpg';
import restaurant from '../images/restaurant/restaurant.jpg';
import eljardinsign from '../images/restaurant/eljardinsign.jpg';
import domicilio from '../images/domicilio.jpg';
import petfriendly from '../images/petfriendly.jpg';
import caldoillus from '../images/caldoillus.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLeaf } from '@fortawesome/free-solid-svg-icons';
import yummy2 from '../images/yummy2.jpg';
import fanescasantisima from '../images/fanescasantisima.jpg';
import bolonesyum from '../images/bolonesyum.jpg';
import churrasco from '../images/menu/churrasco.jpg';
import guatita from '../images/guatita.jpg';
import marytierrazoom from '../images/marytierrazoom.jpg';

export default function Home() {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const topContainer = useRef();

  // To make sure page starts from the top
  useEffect(() => {
    topContainer.current.scrollIntoView({
      block: 'end',
      behavior: 'smooth',
    });
  }, []);

  return (
    <section id="home" className="home">
      <div ref={topContainer} />
      <div className="home-bx">
        <Carousel
          responsive={responsive}
          infinite={true}
          className="home-slider"
        >
          <div>
            <img src={caldoaerial} alt="caldo" className="slider-img" />
          </div>
          <div>
            <img src={yummy} alt="yummy" className="slider-img" />
          </div>

          <div>
            <img src={eljardinsign} alt="eljardinsign" className="slider-img" />
          </div>
          <div>
            <img src={restaurant} alt="restaurant" className="slider-img" />
          </div>
        </Carousel>
      </div>
      <div className="slideInUp">
        <motion.div
          className="tile"
          initial={{ opacity: -1 }}
          whileInView={{ opacity: 1 }}
        ></motion.div>
        <motion.div
          className="emerald-wrap"
          initial={{ opacity: -1 }}
          whileInView={{ opacity: 1 }}
        >
          <h4 className="bienvenidos">
            <MultiLingualContent contentID="bienvenidos" />
            <FontAwesomeIcon icon={faLeaf} className="leaf" />
          </h4>
          <h1>El Jardín Tradicional</h1>
          <h3>
            <MultiLingualContent contentID="generaciones" />
          </h3>
          <p>
            <MultiLingualContent contentID="ganadores" />
          </p>
          <a href="/about" className="aprende">
            <MultiLingualContent contentID="aprende" />
          </a>
        </motion.div>
      </div>
      <div className="tile"></div>
      <div className="ads">
        <motion.img
          src={domicilio}
          alt="domicilioservice"
          className="domicilio"
          whileHover={{ scale: 1.03 }}
        />
        <motion.img
          src={caldoillus}
          alt="caldodebola"
          className="domicilio"
          whileHover={{ scale: 1.03 }}
        />
        <motion.img
          src={petfriendly}
          alt="petfriendly"
          className="domicilio"
          whileHover={{ scale: 1.03 }}
        />
      </div>

      <motion.div
        initial={{ scale: 0 }}
        whileInView={{ scale: 1 }}
        className="bookings"
      >
        <a
          href="https://wa.link/nawtnt"
          target="_blank"
          rel="noreferrer"
          title="pídelo a domicilio"
        >
          <Button variant="warning" className="book-btn">
            <MultiLingualContent contentID="domicilio" />
          </Button>
        </a>
        <a
          href="https://www.rappi.com.ec/restaurantes/10810-restaurante-el-jardin-guayaquil?fbclid=IwAR0z-0zkyFJfs9nx6_4A7dIJm8BnSjCmM1vNiO4QSFVp7surg_AOSsbTT3w"
          target="_blank"
          rel="noreferrer"
          title="pídelo a rappi"
        >
          <Button variant="danger" className="book-btn">
            <MultiLingualContent contentID="rappi" />
          </Button>
        </a>
      </motion.div>
      <div className="slideInUp">
        <motion.div
          className="tile"
          initial={{ opacity: -1 }}
          whileInView={{ opacity: 1 }}
        ></motion.div>

        <motion.div
          className="emerald-wrap-2
          d-flex
          "
          initial={{ opacity: -1 }}
          whileInView={{ opacity: 1 }}
        >
          <div className="comida">
            <h4 className="menu">
              <MultiLingualContent contentID="menu" />
            </h4>
            <p>
              <MultiLingualContent contentID="platos" />
            </p>
            <div className="comida-fotos">
              <img src={yummy2} alt="menufoto" />
              <img src={marytierrazoom} alt="menufoto" />
              <img src={fanescasantisima} alt="menufoto" />
              <img src={bolonesyum} alt="menufoto" />
              <img src={churrasco} alt="menufoto" />
              <img src={guatita} alt="menufoto" />
            </div>
            <a href="/menu">
              <MultiLingualContent contentID="ver" />
            </a>
          </div>
        </motion.div>
      </div>
      <div className="tile"></div>
    </section>
  );
}
