import { useMemo } from 'react';
import { GoogleMap, useLoadScript, MarkerF } from '@react-google-maps/api';
import './Map.css';

export default function Map() {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyA2X5BuOvtO1vrO9LFSAnNfQdAtgRXyOvE',
  });

  if (!isLoaded) return <div>Loading...</div>;

  return <MapRender />;
}

const onMarkerClick = () => {
  window.open(
    'https://www.google.com/maps/place/El+Jardin+Restaurant/@-2.210453,-79.887596,19z/data=!4m5!3m4!1s0x0:0x105ff14e5aad0d4b!8m2!3d-2.2106737!4d-79.8876179?hl=en-US'
  );
};

const onMarkerClickNorth = () => {
  window.open('https://maps.app.goo.gl/VoLDCpZRx9Ao39Z17');
};

function MapRender() {
  const center = useMemo(
    () => ({ lat: -2.210595652977979, lng: -79.88762371577945 }),
    []
  );
  const north = useMemo(() => ({ lat: -2.17065, lng: -79.894686 }), []);
  return (
    <div className="map-container">
      <GoogleMap
        zoom={12}
        center={center}
        north={north}
        mapContainerClassName="map"
      >
        <MarkerF position={center} onClick={onMarkerClick} />
        <MarkerF position={north} onClick={onMarkerClickNorth} />
      </GoogleMap>
    </div>
  );
}
