import MultiLingualContent from '../hooks/MultiLingualContent.js';

//animation
import { motion } from 'framer-motion';

//styles & icons
import './MenuGrid.css';
import { Container, Row, Col } from 'react-bootstrap';

//images
import apanada from '../images/menu/apanada.jpg';
import bolon from '../images/menu/bolon.jpg';
import secodechivo from '../images/menu/secodechivo.jpg';
import tallarinverde from '../images/menu/tallarinverde.jpg';
import tigrillomixto from '../images/menu/tigrillomixto.jpg';
import tigrillodequeso from '../images/menu/tigrillodequeso.jpg';
import churrasco from '../images/menu/churrasco.jpg';
import cazuelamixta from '../images/menu/cazuelamixta.jpg';
import bandera from '../images/menu/bandera.jpg';
import pollo from '../images/menu/pollo.jpg';
import caldomarytierra from '../images/menu/caldomarytierra.jpg';
import caldo from '../images/menu/caldo.jpg';
import bistecdecarne from '../images/menu/bistecdecarne.jpg';
import tigrillodechicharron from '../images/menu/tigrillodechicharron.jpg';
import bolonmixto from '../images/menu/bolonmixto.jpg';
import gallina from '../images/menu/gallina.jpg';
import ceviche from '../images/menu/ceviche.jpg';
import cazuelacamaron from '../images/menu/cazuelacamaron.jpg';
import page1 from '../images/menu/page1.jpg';
import page2 from '../images/menu/page2.jpg';
import page3 from '../images/menu/page3.jpg';

export default function MenuGrid({ setSelectedImg }) {
  return (
    <section className="menu">
      <Container>
        <Row>
          <h1>{''}</h1>
          <h2>
            {' '}
            <MultiLingualContent contentID="compmenu" />
          </h2>
          <div className="menu-break"></div>
          <Col xs={4} md={4} xl={4}>
            <motion.img
              src={page1}
              alt="page1"
              className="menu-comp"
              whileHover={{ scale: 1.05 }}
              onClick={() => setSelectedImg(page1)}
            />
            <p className="p-comp">
              {' '}
              <MultiLingualContent contentID="manana" />
            </p>
          </Col>
          <Col xs={4} md={4} xl={4}>
            <motion.img
              src={page2}
              alt="page2"
              className="menu-comp"
              whileHover={{ scale: 1.05 }}
              onClick={() => setSelectedImg(page2)}
            />
            <p className="p-comp">
              {' '}
              <MultiLingualContent contentID="tarde" />
            </p>
          </Col>
          <Col xs={4} md={4} xl={4}>
            <motion.img
              src={page3}
              alt="page3"
              className="menu-comp"
              whileHover={{ scale: 1.05 }}
              onClick={() => setSelectedImg(page3)}
            />
            <p className="p-comp">
              {' '}
              <MultiLingualContent contentID="postre" />
            </p>
          </Col>
        </Row>
        <h2>
          {' '}
          <MultiLingualContent contentID="galeria" />
        </h2>
        <div className="menu-break"></div>
        <div className="galleria-background">
          <Row>
            <Col xs={4} md={4} xl={4}>
              <motion.img
                src={caldo}
                alt="caldodebola"
                className="menu-img"
                id="1"
                whileHover={{ scale: 1.05 }}
                onClick={() => setSelectedImg(caldo)}
              />
              <p>Caldo de bola tradicional</p>
            </Col>
            <Col xs={4} md={4} xl={4}>
              <motion.img
                src={caldomarytierra}
                alt="caldomarytierra"
                className="menu-img"
                whileHover={{ scale: 1.05 }}
                onClick={() => setSelectedImg(caldomarytierra)}
              />
              <p>Caldo de bola mar y tierra</p>
            </Col>
            <Col xs={4} md={4} xl={4}>
              <motion.img
                src={cazuelamixta}
                alt="cazuelamixta"
                className="menu-img"
                whileHover={{ scale: 1.05 }}
                onClick={() => setSelectedImg(cazuelamixta)}
              />
              <p>Cazuela mixta pescado y camarón</p>
            </Col>
          </Row>
          <Row>
            <Col xs={4} md={4} xl={4}>
              <motion.img
                src={bolon}
                alt="bolon"
                className="menu-img"
                whileHover={{ scale: 1.05 }}
                onClick={() => setSelectedImg(bolon)}
              />
              <p>Bolon de chicharrón con huevo frito</p>
            </Col>
            <Col xs={4} md={4} xl={4}>
              <motion.img
                src={bolonmixto}
                alt="bolonmixto"
                className="menu-img"
                whileHover={{ scale: 1.05 }}
                onClick={() => setSelectedImg(bolonmixto)}
              />
              <p>Bolon mixto con huevo frito</p>
            </Col>
            <Col xs={4} md={4} xl={4}>
              <motion.img
                src={bistecdecarne}
                alt="bistecdecarne"
                className="menu-img"
                whileHover={{ scale: 1.05 }}
                onClick={() => setSelectedImg(bistecdecarne)}
              />
              <p>Bistec de carne con bolon mixto</p>
            </Col>
          </Row>
          <Row>
            <Col xs={4} md={4} xl={4}>
              <motion.img
                src={bandera}
                alt="bandera"
                className="menu-img"
                whileHover={{ scale: 1.05 }}
                onClick={() => setSelectedImg(bandera)}
              />
              <p>Bandera</p>
            </Col>
            <Col xs={4} md={4} xl={4}>
              <motion.img
                src={tallarinverde}
                alt="tallarinverde"
                className="menu-img"
                whileHover={{ scale: 1.05 }}
                onClick={() => setSelectedImg(tallarinverde)}
              />
              <p>Carne apanada con tallarín verde</p>
            </Col>
            <Col xs={4} md={4} xl={4}>
              <motion.img
                src={apanada}
                alt="apanada"
                className="menu-img"
                whileHover={{ scale: 1.05 }}
                onClick={() => setSelectedImg(apanada)}
              />
              <p>Carne apanada con puré</p>
            </Col>
          </Row>
          <Row>
            <Col xs={4} md={4} xl={4}>
              <motion.img
                src={pollo}
                alt="pollo"
                className="menu-img"
                whileHover={{ scale: 1.05 }}
                onClick={() => setSelectedImg(pollo)}
              />
              <p>Pollo con puré y arroz</p>
            </Col>
            <Col xs={4} md={4} xl={4}>
              <motion.img
                src={secodechivo}
                alt="secodechivo"
                className="menu-img"
                whileHover={{ scale: 1.05 }}
                onClick={() => setSelectedImg(secodechivo)}
              />
              <p>Seco de chivo</p>
            </Col>
            <Col xs={4} md={4} xl={4}>
              <motion.img
                src={churrasco}
                alt="churrasco"
                className="menu-img"
                whileHover={{ scale: 1.05 }}
                onClick={() => setSelectedImg(churrasco)}
              />
              <p>Churrasco</p>
            </Col>
          </Row>
          <Row>
            <Col xs={4} md={4} xl={4}>
              <motion.img
                src={tigrillomixto}
                alt="tigrillomixto"
                className="menu-img"
                whileHover={{ scale: 1.05 }}
                onClick={() => setSelectedImg(tigrillomixto)}
              />
              <p>Tigrillo mixto</p>
            </Col>
            <Col xs={4} md={4} xl={4}>
              <motion.img
                src={tigrillodechicharron}
                alt="tigrillodechicharron"
                className="menu-img"
                whileHover={{ scale: 1.05 }}
                onClick={() => setSelectedImg(tigrillodechicharron)}
              />
              <p>Tigrillo de chicharrón</p>
            </Col>
            <Col xs={4} md={4} xl={4}>
              <motion.img
                src={tigrillodequeso}
                alt="tigrillodequeso"
                className="menu-img"
                whileHover={{ scale: 1.05 }}
                onClick={() => setSelectedImg(tigrillodequeso)}
              />
              <p>Tigrillo de queso</p>
            </Col>
          </Row>
          <Row>
            <Col xs={4} md={4} xl={4}>
              <motion.img
                src={gallina}
                alt="gallina"
                className="menu-img"
                whileHover={{ scale: 1.05 }}
                onClick={() => setSelectedImg(gallina)}
              />
              <p>Caldo de gallina</p>
            </Col>
            <Col>
              <motion.img
                src={ceviche}
                alt="ceviche"
                className="menu-img"
                whileHover={{ scale: 1.05 }}
                onClick={() => setSelectedImg(ceviche)}
              />
              <p>Ceviche de Camarón</p>
            </Col>
            <Col>
              <motion.img
                src={cazuelacamaron}
                alt="cazuelacamaron"
                className="menu-img"
                whileHover={{ scale: 1.05 }}
                onClick={() => setSelectedImg(cazuelacamaron)}
              />
              <p>Cazuela de Camarón</p>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
}
