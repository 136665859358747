import { useEffect, useRef } from 'react';
import MultiLingualContent from '../hooks/MultiLingualContent.js';

//animation
import { motion } from 'framer-motion';

//styles & icons
import './About.css';
import jardinsign from '../images/restaurant/jardinsign.jpg';
import aerial from '../images/restaurant/aerial.jpg';
import restaurant2 from '../images/restaurant/restaurant2.jpg';
import lachef1 from '../images/restaurant/lachef1.jpg';
import familia from '../images/restaurant/familia.jpg';
import marytierrazoom from '../images/marytierrazoom.jpg';

export default function About() {
  const topContainer = useRef();

  // To make sure page starts from the top
  useEffect(() => {
    topContainer.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
  }, []);

  return (
    <section className="about">
      <div ref={topContainer} />

      <div className="d-flex justify-content-around top-fotos">
        <img src={jardinsign} alt="jardinsign" className="jardinsign" />
        <img src={aerial} alt="aerialview" className="aerial" />
        <img src={restaurant2} alt="restaurantfoto" className="restaurant-2" />
      </div>

      <div className="d-flex justify-content-around mid-fotos">
        <img src={familia} alt="eljardinchef" className="familia" />

        <div>
          <h2>El Jardín Tradicional</h2>
          <p className="about-jardin">
            <MultiLingualContent contentID="quienessomos" />
          </p>
        </div>
        <img
          src={marytierrazoom}
          alt="caldomarytierra"
          className="marytierra"
        />
      </div>
      <motion.div
        className="about-chef"
        initial={{ opacity: -1 }}
        whileInView={{ opacity: 1 }}
      >
        <h2>La Chef Maria Fernanda Cepeda</h2>
        <img src={lachef1} alt="eljardinchef" className="lachef" />
      </motion.div>
    </section>
  );
}
