import { createContext, useState } from 'react';
import { Translations } from '../comps/Translations.js';

export const LanguageContext = createContext(Translations);

export function LanguageProvider({ children }) {
  const [language, setLanguage] = useState('spanish');

  function toggleSpanish() {
    setLanguage('spanish');
  }

  function toggleEnglish() {
    setLanguage('english');
  }

  return (
    <LanguageContext.Provider
      value={{ language, toggleSpanish, toggleEnglish }}
    >
      {children}
    </LanguageContext.Provider>
  );
}
