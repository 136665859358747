import { useEffect, useRef } from 'react';
import Map from '../comps/Map.js';
import MultiLingualContent from '../hooks/MultiLingualContent.js';

//animation
import { motion } from 'framer-motion';

// styles & icons
import './Contact.css';
import { Container, Row, Col } from 'react-bootstrap';
import caldoillus from '../images/caldoillus2.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faTiktok } from '@fortawesome/free-brands-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';

export default function Contact() {
  const topContainer = useRef();

  // To make sure page starts from the top
  useEffect(() => {
    topContainer.current.scrollIntoView({
      block: 'end',
      behavior: 'smooth',
    });
  }, []);

  return (
    <section className="contact" id="contact">
      <div ref={topContainer} />
      <Map />
      <Container>
        <Row>
          <Col>
            <div className="address">
              <h3>
                <MultiLingualContent contentID="direccion" />
              </h3>
              <h4>
                <MultiLingualContent contentID="address" />
              </h4>
              <p>
                <strong>
                  Interior: S/N, Piso: S/N, Chimborazo, Exterior: 2619, Centro,
                  Guayaquil 090308, Ecuador
                </strong>
              </p>
              <br />
              <h4>
                <MultiLingualContent contentID="address2" />
              </h4>
              <p>
                <strong>
                  Avenida plaza Dañin y avenida H alcivar parroquia Tarqui,
                  manzana 1 solar 48
                </strong>
              </p>
            </div>
          </Col>
        </Row>
      </Container>

      <div className="d-flex justify-content-center contact-info">
        <div>
          <img src={caldoillus} alt="caldodebola" className="caldo-illus" />
        </div>

        <div>
          <motion.div className="horas" whileHover={{ scale: 1.03 }}>
            <h4>
              {' '}
              <MultiLingualContent contentID="hours" />
            </h4>
            <ul>
              <li>
                <strong>
                  {' '}
                  <MultiLingualContent contentID="monday" />
                </strong>{' '}
                7:30am-3:30pm
              </li>
              <li>
                <strong>
                  {' '}
                  <MultiLingualContent contentID="tuesday" />
                </strong>{' '}
                7:30am-3:30pm
              </li>
              <li>
                <strong>
                  {' '}
                  <MultiLingualContent contentID="wednesday" />
                </strong>{' '}
                7:30am-3:30pm
              </li>
              <li>
                <strong>
                  {' '}
                  <MultiLingualContent contentID="thursday" />
                </strong>{' '}
                7:30am-3:30pm
              </li>
              <li>
                <strong>
                  {' '}
                  <MultiLingualContent contentID="friday" />
                </strong>{' '}
                7:30am-3:30pm
              </li>
              <li>
                <strong>
                  {' '}
                  <MultiLingualContent contentID="saturday" />
                </strong>{' '}
                7:30am-3:30pm
              </li>
              <li>
                <strong>
                  {' '}
                  <MultiLingualContent contentID="sunday" />
                </strong>{' '}
                7:30am-3:30pm
              </li>
            </ul>
          </motion.div>
          <motion.div className="contacto-wrap" whileHover={{ scale: 1.03 }}>
            <h4>
              {' '}
              <MultiLingualContent contentID="contact" />
            </h4>
            <div className="social-icon">
              <a
                href="https://www.facebook.com/restaurant.eljardin/"
                target="_blank"
                rel="noreferrer"
                title="eljardin guayaquil facebook"
              >
                <FontAwesomeIcon icon={faFacebook} className="facebook" />
              </a>
              <a
                href="https://www.instagram.com/restaurant.eljardin/"
                target="_blank"
                rel="noreferrer"
                title="eljardin guayaquil instagram"
              >
                <FontAwesomeIcon icon={faInstagram} className="instagram" />
              </a>{' '}
              <a
                href="https://www.tiktok.com/@eljardin_caldodebola?_t=8VvOO1bjJ81&_r=1&fbclid=IwAR3VmBSZGkYoN77FIeg8vEN0Mw8AvprC7Pi-CeytYg6ZXredukIlo-yEKbs"
                target="_blank"
                rel="noreferrer"
                title="eljardin guayaquil tiktok"
              >
                <FontAwesomeIcon icon={faTiktok} className="tiktok" />
              </a>
              <span className="phone-contact">
                <FontAwesomeIcon icon={faPhone} className="phone" />
                <h6>+593 98 627 6861</h6>
              </span>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
}
