import { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Link, Navigate } from 'react-router-dom';
import MultiLingualContent from '../hooks/MultiLingualContent.js';
import ToggleLanguage from './ToggleLanguage.js';

//styles & icons
import './Navibar.css';
import { Nav, Navbar, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import logo from '../images/logo.jpg';

//pages
import Home from '../pages/Home';
import Menu from '../pages/Menu';
import About from '../pages/About';
import Media from '../pages/Media';
import Contact from '../pages/Contact';

export default function Navibar() {
  const [activeLink, setActiveLink] = useState('home');
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  };

  return (
    <BrowserRouter>
      <Navbar
        collapseOnSelect
        expand="md"
        className={scrolled ? 'scrolled' : ''}
      >
        <Container>
          <Navbar.Brand as={Link} to="/">
            <img src={logo} alt="logo" className="logo-nav" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav"></Navbar.Toggle>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link
                eventKey="1"
                as={Link}
                to="/"
                className={
                  activeLink === 'home' ? 'active navbar-link' : 'navbar-link'
                }
                onClick={() => onUpdateActiveLink('home')}
              >
                <MultiLingualContent contentID="home" />
              </Nav.Link>
              <Nav.Link
                eventKey="2"
                as={Link}
                to="/menu"
                className={
                  activeLink === 'menu' ? 'active navbar-link' : 'navbar-link'
                }
                onClick={() => onUpdateActiveLink('menu')}
              >
                <MultiLingualContent contentID="menu" />
              </Nav.Link>
              <Nav.Link
                eventKey="3"
                as={Link}
                to="/about"
                className={
                  activeLink === 'about' ? 'active navbar-link' : 'navbar-link'
                }
                onClick={() => onUpdateActiveLink('about')}
              >
                <MultiLingualContent contentID="about" />
              </Nav.Link>
              <Nav.Link
                eventKey="4"
                as={Link}
                to="/media"
                className={
                  activeLink === 'media' ? 'active navbar-link' : 'navbar-link'
                }
                onClick={() => onUpdateActiveLink('media')}
              >
                <MultiLingualContent contentID="media" />
              </Nav.Link>
              <Nav.Link
                eventKey="6"
                as={Link}
                to="/contact"
                className={
                  activeLink === 'contact'
                    ? 'active navbar-link'
                    : 'navbar-link'
                }
                onClick={() => onUpdateActiveLink('contact')}
              >
                <MultiLingualContent contentID="contact" />
              </Nav.Link>
            </Nav>
            <ToggleLanguage />
            <span className="nav-phone-contact">
              <FontAwesomeIcon icon={faPhone} className="nav-phone" />
              <h6>+593 98 627 6861</h6>
            </span>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/menu" element={<Menu />} />
          <Route path="/about" element={<About />} />
          <Route path="/media" element={<Media />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}
