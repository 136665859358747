import MultiLingualContent from '../hooks/MultiLingualContent.js';

//styles & icons
import './Footer.css';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faTiktok } from '@fortawesome/free-brands-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';

export default function Footer() {
  return (
    <section className="footer" id="footer">
      <div className="footer-wrap">
        <Container>
          <Row>
            <Col>
              <div className="copyright">
                <p>©2024 El Jardín Tradicional</p>
              </div>
            </Col>
            <Col>
              <p>
                Interior: S/N, Piso: S/N, Chimborazo, Exterior: 2619, Centro,
                Guayaquil 090308, Ecuador
              </p>
              <p>
                <MultiLingualContent contentID="abierto" />
              </p>
            </Col>
            <Col>
              <div className="social-icon">
                <a
                  href="https://www.facebook.com/restaurant.eljardin/"
                  target="_blank"
                  rel="noreferrer"
                  title="eljardin guayaquil facebook"
                >
                  <FontAwesomeIcon icon={faFacebook} className="facebook" />
                </a>
                <a
                  href="https://www.instagram.com/restaurant.eljardin/"
                  target="_blank"
                  rel="noreferrer"
                  title="eljardin guayaquil instagram"
                >
                  <FontAwesomeIcon icon={faInstagram} className="instagram" />
                </a>

                <a
                  href="https://www.tiktok.com/@eljardin_caldodebola?_t=8VvOO1bjJ81&_r=1&fbclid=IwAR3VmBSZGkYoN77FIeg8vEN0Mw8AvprC7Pi-CeytYg6ZXredukIlo-yEKbs"
                  target="_blank"
                  rel="noreferrer"
                  title="eljardin guayaquil tiktok"
                >
                  <FontAwesomeIcon icon={faTiktok} className="tiktok" />
                </a>
              </div>
              <span className="phone-contact">
                <FontAwesomeIcon icon={faPhone} className="phone" />
                <h6>+593 98 627 6861</h6>
              </span>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
}
