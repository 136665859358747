import { useState } from 'react';
import Modal from '../comps/Modal.js';
import MenuGrid from '../comps/MenuGrid.js';
import { useEffect, useRef } from 'react';

export default function Menu() {
  const [selectedImg, setSelectedImg] = useState(null);

  const topContainer = useRef();

  // To make sure page starts from the top
  useEffect(() => {
    topContainer.current.scrollIntoView({
      block: 'end',
      behavior: 'smooth',
    });
  }, []);

  return (
    <div className="menu">
      <div ref={topContainer} />
      <MenuGrid setSelectedImg={setSelectedImg} />
      {selectedImg && (
        <Modal selectedImg={selectedImg} setSelectedImg={setSelectedImg} />
      )}
    </div>
  );
}
