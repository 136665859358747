import { LanguageProvider } from './context/LanguageContext.js';

//styles & css
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

//comps
import Navibar from './comps/Navibar.js';
import Footer from './comps/Footer.js';
import ScrollUp from './comps/ScrollUp';

function App() {
  return (
    <LanguageProvider>
      <div className="App">
        <ScrollUp />
        <Navibar />
        <Footer />
      </div>
    </LanguageProvider>
  );
}

export default App;
