import { useContext } from 'react';
import { LanguageContext } from '../context/LanguageContext.js';
import './ToggleLanguage.css';

export default function ToggleLanguage() {
  const { toggleEnglish, toggleSpanish, language } = useContext(
    LanguageContext
  );

  if (language === 'spanish') {
    return (
      <div>
        <span className="togglespan">
          <h5>ES </h5>
          <button onClick={toggleEnglish} className="toggle-btn">
            EN
          </button>
        </span>
      </div>
    );
  } else {
    return (
      <div>
        <span className="togglespan">
          <button onClick={toggleSpanish} className="toggle-btn">
            ES
          </button>
          <h5>EN</h5>
        </span>
      </div>
    );
  }
}
