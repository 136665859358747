import { useEffect, useRef } from 'react';
import MultiLingualContent from '../hooks/MultiLingualContent.js';

//styles & icons
import './Media.css';
import molecular from '../images/caldodebolamolecular.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReadme } from '@fortawesome/free-brands-svg-icons';

export default function Media() {
  const topContainer = useRef();

  // To make sure page starts from the top
  useEffect(() => {
    topContainer.current.scrollIntoView({
      block: 'end',
      behavior: 'smooth',
    });
  }, []);

  return (
    <div>
      <div ref={topContainer} />
      <h3 className="news-date">
        <MultiLingualContent contentID="enero" />
      </h3>
      <div className="media">
        <img src={molecular} className="molecular" alt="caldodebolamolecular" />
        <h4 className="article-heading">
          <MultiLingualContent contentID="molecular" />
          <br />
          <MultiLingualContent contentID="evolucion" />
          <br />
          <a
            href="https://bagre.life/fotoportafolio/caldo-de-bola-molecular-la-evolucion-de-lo-tipico/"
            target="blank"
            rel="noreferrer"
            title="Caldo de bola molecular, la evolución de lo típico"
          >
            <FontAwesomeIcon icon={faReadme} className="readme" />
          </a>
        </h4>
      </div>
    </div>
  );
}
