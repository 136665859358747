export const Translations = {
  english: {
    home: 'Home',
    menu: 'Menu',
    about: 'About',
    media: 'Media',
    contact: 'Contact',
    direccion: 'Locations',
    special: 'Special Offer',
    bienvenidos: 'Welcome!',
    generaciones: 'For 3 generations',
    aprende: ' Learn more →',
    domicilio: 'Order on Domicilio',
    rappi: 'Order on Rappi',
    platos: 'Tasty dishes made with local ingredients',
    ver: 'View the complete menu →',
    notice: 'Please check back here for future events...',
    abierto: 'Open every day from 7:30am-3:30pm',
    address: 'Main restaurant',
    address2: 'North Side location',
    hours: 'Hours',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',
    compmenu: 'Complete Menu',
    manana: 'Breakfast',
    tarde: 'Lunch',
    postre: 'Desserts & Drinks',
    galeria: 'Gallery',
    ganadores:
      'We are winners of the best Caldo de Bola in Guayaquil recognized by the Guayas tourism entities.',
    quienessomos:
      'We are a traditional Ecuadorian restaurant with recognition from the municipal tourism of Guayaquil and the prefecture of Guayas, special guests of the most famous gastronomic fair in Ecuador "Raíces", with our star dish "ball broth" (caldo de bola), and our award- winning dish "ball broth land and sea" (caldo de bola mar y tierra). We are open every day from Monday to Sunday.',
    enero: 'January 2023',
    molecular: 'Molecular ball broth soup,',
    evolucion: 'An evolution from the typical',
  },
  spanish: {
    home: 'Inicio',
    menu: 'Menú',
    about: 'Quiénes Somos',
    media: 'Medios',
    contact: 'Contacto',
    direccion: 'Dirección',
    bienvenidos: 'Bienvenidos!',
    generaciones: 'Por 3 generaciones',
    aprende: ' Aprende más →',
    domicilio: 'Pídelo a Domicilio',
    rappi: '  Pídelo a Rappi',
    platos: 'Sabrosos platos elaborados con ingredientes locales',
    ver: 'Ver el menú completo →',
    notice: 'Vuelva a consultar aquí para futuros eventos...',
    abierto: 'Abierto todos los días 7:30am-3:30pm',
    address: 'Restaurante principal',
    address2: 'Restaurante del norte',
    hours: 'Horas',
    monday: 'Lunes',
    tuesday: 'Martes',
    wednesday: 'Miércoles',
    thursday: 'Jueves',
    friday: 'Viernes',
    saturday: 'Sábado',
    sunday: 'Domingo',
    compmenu: 'Menú Completo',
    manana: 'Por La Mañana',
    tarde: 'Por La Tarde',
    postre: 'Postres y Bebidas',
    galeria: 'Galería',
    ganadores:
      'Somos ganadores del mejor Caldo de Bola de Guayaquil reconocido por las entidades de turismo del Guayas.',
    quienessomos:
      'Nosotros somos un restaurante tradicional ecuatoriano con reconocimientos del turismo municipal de Guayaquil y la prefectura del Guayas, invitado especial de la feria gastronómica más famosa del Ecuador “raíces” con nuestro plato Estrella “caldo de bola” y nuestro galardonado plato “caldo de bola mar y tierra”. Abierto todos los días de lunes a domingos.',
    enero: 'Enero 2023',
    molecular: 'Caldo de bola molecular,',
    evolucion: 'la evolución de lo típico',
  },
};
